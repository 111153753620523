const hamburgerBtn = document.querySelector(".hamburger-btn");
const mainNav = document.querySelector(".main-navigation");

if (hamburgerBtn && mainNav) {
  hamburgerBtn.addEventListener("click", () => {
    document.querySelector(".hamburger-btn").classList.toggle("open");
    mainNav.classList.toggle("main-navigation--open");
  });
}

const menuItemsWithChildren = document.querySelectorAll(
  ".menu-item-has-children"
);

if (menuItemsWithChildren) {
  menuItemsWithChildren.forEach((menuItem) => {
    const link = menuItem.querySelector("a");
    const icon = `<span class="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2938_2003)">
    <path d="M10 7L15 12L10 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_2938_2003">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg></span>
    `;
    link.innerHTML = link.innerHTML + icon;

    const iconBtn = menuItem.querySelector(".icon");
    iconBtn.addEventListener("click", (e) => {
      e.preventDefault();
      menuItem.classList.toggle("menu-item-has-children--open");
    });
  });
}
